<template>
  <form id="frm_action">
    <div class="p-formgrid">
     <div>
          <div class="p-card-field ">
            <label class="p-col-fixed div-label txt-right">Kiện hàng <span style="color: red">*</span></label>
            <div class="p-col">
              <Textarea :autoResize="true"  rows="1" maxlength="255" required v-model="parcel.name"  id="name" type="text" class="p-col-width text-area border_color" placeholder="Nhập " />
            </div>
          </div>
       <div class="p-card-field width_size ">
         <label class="p-col-fixed div-label txt-right margin dif " id="label">Kích thước <span style="color: red">*</span></label>
         <div class="p-inputgroup input-dif custom-group-number">
<!--           <InputNumber
               mode="decimal"
               :minFractionDigits="0"
               :maxFractionDigits="2"
               locale="de-DE"
               v-model="parcel.size"
               placeholder="Nhập"
           />-->
           <InputNumber  id="size" class="p-col-width input_distance width_dif border_color" placeholder="Nhập"  v-model="parcel.size" mode="decimal" :minFractionDigits="3" />
<!--           <InputText type="text"   @input="validateNumericInput" v-model="parcel.size" id="size" placeholder="Nhập"  class="p-col-width input_distance width_dif border_color"/>
           --><span class="p-inputgroup-addon" id="km">m³</span>
         </div>
       </div>
       <div style="margin-top: 9px" class="p-card-field width_size ">
         <label class="p-col-fixed div-label txt-right margin dif " id="label">Thứ tự </label>
         <div class="p-inputgroup input-dif custom-group-number">
           <InputNumber  class="p-col-width  border_color" placeholder="Nhập"  v-model="parcel.ordinal" />
         </div>
       </div>

          <div class="p-card-field div_check">
            <div class="p-col checkbox">
              <Checkbox  v-model="parcel.checked" @click="active" :binary="true"/>
              <label class="p-col-fixed txt-right text">Hiển thị</label>
            </div>
          </div>
     </div>
      <Toolbar class="fixed-bottom-toolbar">
        <template slot="right">
          <Button
              :label="$constants.TEXT_BUTTON.BACK"
              @click="backToList()"
              class="p-button-warning callback-btn"
              id="back"
          />
          <Button
              v-if="mode == 'edit'"
              :label="$constants.TEXT_BUTTON.EDIT"
              @click="saveData()"
              class="main-style-button"
          />
          <Button
              v-else
              :label="$constants.TEXT_BUTTON.ADD"
              @click="saveData()"
              class="main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>
</template>
<script>
// import DataServices from '@/core/DataServices'
// import gql from "graphql-tag";

export default {
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {

      parcel:{
        name: '',
        size: '',
        checked: true,
      },
      code: '',
      validationErrors: {},
      formMode: this.mode,
      images: [],
      imagesDeletes: [],
      maxFileSize: 1000000, // 1MB
      maxFiles: 10,
      account_exist: false,
      firebaseID: null,
      userID: null,
      list_group_role:[],
      saving:false,
      addressData:{"street_number":"251","route":"Quang Trung","administrative_area_level_1":"Thành phố Hồ Chí Minh","country":"Việt Nam","latitude":10.8286588,"longitude":106.6732573},
      placeResultData:{"address_components":[{"long_name":"251","short_name":"251","types":["street_number"]},{"long_name":"Quang Trung","short_name":"Quang Trung","types":["route"]},{"long_name":"Gò Vấp","short_name":"Gò Vấp","types":["administrative_area_level_2","political"]},{"long_name":"Thành phố Hồ Chí Minh","short_name":"Thành phố Hồ Chí Minh","types":["administrative_area_level_1","political"]},{"long_name":"Việt Nam","short_name":"VN","types":["country","political"]}],"adr_address":"<span class=\"street-address\">251 Quang Trung</span>, <span class=\"extended-address\">Phường 10</span>, <span class=\"locality\">Gò Vấp</span>, <span class=\"region\">Thành phố Hồ Chí Minh</span>, <span class=\"country-name\">Việt Nam</span>","formatted_address":"251 Quang Trung, Phường 10, Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","geometry":{"location":{"lat":10.8286588,"lng":106.6732573},"viewport":{"south":10.8273459697085,"west":106.6719226697085,"north":10.8300439302915,"east":106.6746206302915}},"icon":"https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png","name":"251 Quang Trung","place_id":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","plus_code":{"compound_code":"RMHF+F8 Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","global_code":"7P28RMHF+F8"},"reference":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","types":["street_address"],"url":"https://maps.google.com/?q=251+Quang+Trung,+Ph%C6%B0%E1%BB%9Dng+10,+G%C3%B2+V%E1%BA%A5p,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam&ftid=0x31752903d53de0f3:0x662fc5f9fd313d5a","utc_offset":420,"html_attributions":[],"utc_offset_minutes":420}
    }
  },
  async mounted() {

    // đổ dữ liệu khi edit
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      console.log("sdsds111", this.mode)
      var list_parcel_edit = this.$commonFuction.convertJsonObject(this.model);

      console.log("sasasa",list_parcel_edit)
      this.parcel.checked = this.model.active
      this.parcel.name = this.model.name
      this.parcel.size = this.model.size
      this.parcel.ordinal = this.model.ordinal
    }
  },
  methods: {
     async handleSelectChange() {
    },
    async validateNumericInput() {
      // Xóa các ký tự không phải số hoặc dấu chấm từ giá trị đầu vào
      if (this.parcel.size[0] == ".")
      {
        this.parcel.size = "";
      }
      // if (this.parcel.size[3] == ".")
      // {
      //   console.log("wqwqwqwqwqw")
      //   this.parcel.size[3] = "";
      //   console.log("wqwqwqwqwqw",this.parcel.size[3])
      // }
      this.parcel.size = this.parcel.size.replace(/[^0-9.]/g, "");
      // Kiểm tra nếu có nhiều hơn một dấu chấm, giữ lại chỉ số đầu tiên
      // this.parcel.size = this.parcel.size.replace(/[^\w\s]/gi, "");
      // chặn số
      // this.parcel.size = this.parcel.size.replace(/\d/g, '');
      // this.parcel.size = this.parcel.size.replace(/[^\w\s]/gi, "");
      // chặn chữ
      // this.parcel.size = this.parcel.size.replace(/[^0-9a-zA-Z!@#$%^&*()-_+=]/g, '');
      const dots = this.parcel.size.split('.');
      if (dots.length > 2) {
        this.parcel.size = dots[0] + '.' + dots.slice(1).join('');
      }
    },
    async active() {
    },
    backToList() {
      this.$emit('back');
    },
    async validateData() {
      this.validationErrors = {};

      let name_add = document.getElementById("name");
      if (this.parcel.name == undefined || this.parcel.name == null || this.parcel.name == ''){
        name_add.setCustomValidity("Kiện hàng không được để trống!");
      }else {
        name_add.setCustomValidity("");
      }

      let size_add = document.getElementById("size");
      if (this.parcel.size == undefined || this.parcel.size == null || this.parcel.size == ''){
        size_add.setCustomValidity("Kích thước không được để trống!");
      }else {
        size_add.setCustomValidity("");
      }

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },

    async saveData() {
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
       console.log("test4")
      console.log("checked",this.checked)

      // var company_id = [];
      //
      //  console.log("timid",this.selectedCompany)
      // this.selectedCompany ? this.selectedCompany:0
      // if (this.selectedCompany == 0 || this.selectedCompany == null )
      // {
      //   alert(" Công ty không được trống !!")
      //   return;
      // }
      // var length = this.selectedCompany ? this.selectedCompany :[]
      // for( var i = 0 ; i < length.length ; i++)
      // {
      //   company_id.push(this.selectedCompany[i])
      // }

        var params = {
          ordinal  :this.parcel.ordinal,
          name  :this.parcel.name,
          size:this.parcel.size,
          active:this.parcel.checked
        }
      console.log('saveData', params)
        // console.log("params:",params);
        // console.log("do dai ",this.selectedCompany.length);
        // console.log("selected company",this.selectedCompany[0].id)
        // console.log("company id",params.company_id);
        // console.log(params);
      // var name = (params.name.length) ? params.name.length:0
      // var code = (params.code.length) ? params.code.length:0
      console.log("nasnasasas",name)
console.log("sâsaaa");
        console.log('saveData', params)
        this.$emit('save', params)
    },
  },
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}
.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container{
  height: 40px;
}

//style form add-edit
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{

  padding: 0px!important;

}
.width_size{
  width: 754px!important;
}
.p-col-fixed {
  padding: 0px !important;
  margin:0px 0px 0px 10px !important;
}
.txt-right{
  font-weight: bold;
}
.input-dif{
  //margin-left: 5px!important;
}
.p-inputgroup-addon{
  height: 48px ;
}
#size{
}
.div_check{
  margin-top: 12px!important;
}

.p-formgrid .fixed-bottom-toolbar{
  border-top: none!important;
}

.text{
  font-weight: normal;
  margin: 0px 0px 0px 15px !important;
  padding:0px!important;
}
.layout-main{
  background: #00000029;
}

.p-button-warning{
  border: 1px solid #C40380 !important;

}
#back{
  color: #C40380 !important;
}
.vs__clear{
  display: none;
}

.text-area{
  width: 744px!important;
  min-height: 48px;
}
#input_size{
  width: 747px!important;
  height: 48px!important;
}
.border{
  border: 1px solid #a6a6a6;
}

.input_distance{
  width: 710px!important;
}
.border_color{
  border: 1px solid #C8C8C8;
}

#size{
  height: 48px!important;
  border-left: 0px;
  border-top: 0px;
  border: 1px solid #C8C8C8;
  border-radius: 0px;
  /*margin-left: 10px!important;*/
  //border-top: none!important;
  //border: 1px solid rgba(60, 60, 60, 0.26);;
}
.dif{
  margin-bottom: 9px!important;

  //.text{
  //margin-left: 12px!important;
  //}
  .p-col{
    display: flex!important;
    padding-top: 12px!important;
    margin-top: 12px!important;
  }
  .p-col-fixed{
    //padding:0px!important;
    margin: 12px!important;
  }
  .checkbox{
    margin: 20px 0px 0px 0px!important;

  }
  .border_color{
    border: 1px solid #D0D4D9!important;
  }

}





//responsive
@media screen and (max-width: 600px) {
  .text-area{
    width: 100%!important;
  }
  .width_dif{
    width: 720px!important;
  }
  .p-col{
    padding-right: 0px!important;
    padding-left: 0px!important;
  }
  .width_size{
    width: 100%!important;
    margin-left: 0px!important;
  }
  #size{
    margin-left: 0px!important;
  }
  .div-label{
    margin-left: 0px!important;
  }
}

@media screen and (max-width: 1000px) {
  .text-area{
    width: 100%!important;
  }
  .width_dif{
    width: 720px!important;
  }

  .p-col{
    padding-right: 0px!important;
    padding-left: 0px!important;
  }
  .width_size{
    width: 100%!important;
    margin-left: 0px!important;
  }
  #size{
    margin-left: 0px!important;
  }
  .div-label{
    margin-left: 0px!important;
  }
}
.custom-group-number{
  margin-left: 8px;
}

</style>
